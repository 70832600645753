.about-banner {
  background: url("../../images/BG.svg");
  background-repeat: no-repeat;
  display: flex;
  padding: 70px 0px 0px 0px;
  background-size: cover;
  color: #ffffff;
}

.about-banner-txt {
  display: flex;
}

.aboutus-title {
  font: normal normal bold 38px/57px Segoe UI;
  padding-bottom: 5px;
  position: absolute;
  top: 85px;
}

.about-banner-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-banner-left>.img {
  height: 300px;
  position: relative;
  opacity: 0.8;
  left: -10px;
  padding: 25px 0px 15px 0px;
  width: max-content;
}

.about-banner-right>p {
  font: normal normal normal 14px/24px Segoe UI;
  padding: 45px 0px 25px 30px;
}

/* -------------------who-we-are------------------- */
.about-details-1,
.about-details-2 {
  display: flex;
}

.who-we-are {
  padding: 30px 0px 170px 0px;
}

.who-we-are>h1 {
  font: normal normal bold 38px/56px Segoe UI;
  color: #0866bd;
}

.details-left {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.details-left>div>h2 {
  font: normal normal bold 20px/30px Segoe UI;
  color: #0a387d;
}

.details-left>div>p {
  padding-top: 20px;
  padding-right: 60px;
  font: normal normal normal 15px/25px Segoe UI;
  color: #000000;
}

.details-right {
  max-width: 450px;
}

.details-img-privacy {
  max-width: 300px;
  padding-right: 30px;
}

.details-img-Commitment {
  max-width: 250px;
}

/* ------------------what-we-do---------------------- */

.what-we-do {
  color: #0866bd;
  padding: 30px 0px 0px 0px;
}

.what-we-do>h1 {
  padding: 30px 0px 0px 0px;
  font: normal normal bold 38px/57px Segoe UI;
}

.features-value {
  padding: 35px 0px 0px 0px;
  display: flex;
  grid-template-columns: auto auto auto auto auto auto auto;
  flex-direction: row;
  gap: 60px;
}

.features-value>button {
  text-align: start;
  width: 130px !important;
  height: 46px;
  font: normal normal normal 13px/23px Segoe UI;
  background: #e5eff8;
  letter-spacing: 0px;
  color: #0a387d;
  display: flex;
  opacity: 1;
  border: none;
  border-radius: 5px;
  padding-left: 20px;
  cursor: pointer;
}

.arrow {
  border: solid 10px transparent;
  border-left-color: #000000;
  display: flex;
  align-content: flex-end;
  text-align: end;
}

.values {
  display: flex;
  padding: 20px 0px 170px 0px;
  justify-content: space-around;
  flex-wrap: wrap;
}

.values>img {
  max-width: 150px;
}

.features-value-right {
  width: 50%;
}

.features-value-left {
  width: 50%;
}

.features-value-left>div>h1,
.features-value-right>div>h1 {
  font: normal normal bold 20px/30px Segoe UI;
  color: #0a387d;
}

.features-value-left>div>p,
.features-value-right>div>p {
  font: normal normal normal 15px/25px Segoe UI;
  padding-bottom: 20px;
  color: #000000;
}

@media screen and (max-width: 1024px) {
  .who-we-are {
    padding: 30px 0px 150px 0px;
    max-width: 90%;
  }

  .about-banner-txt {
    max-width: 90%;
  }

  .about-banner {
    padding: 70px 0px 0px 0px;
  }

  .about-banner-right>p {
    padding: 30px 0px 10px 20px;
  }

  .aboutus-title {
    font: normal normal bold 35px/50px Segoe UI;
    padding-bottom: 0px;
  }

  .features-value {
    padding: 20px 30px 0px 40px;
    gap: 40px;
  }

  .what-we-do>h1 {
    padding: 30px 40px 0px 30px;
    font: normal normal bold 38px/57px Segoe UI;
  }

  .values {

    padding: 20px 10px 150px 10px;

  }

  .what-we-do {
    max-width: 90%;
    padding: 30px 0px 0px 0px;
  }
}

@media screen and (max-width: 960px) {
  .about-banner-right>p {
    font: normal normal normal 12px/18px Segoe UI;
  }

  .details-left>div>p {
    padding-top: 20px;
    padding-right: 40px;
    font: normal normal normal 12px/18px Segoe UI;
    color: #000000;
  }

  .details-right {
    max-width: 400px;
  }
}

@media screen and (max-width: 768px) {
  .who-we-are {
    padding: 20px 0px 140px 0px;
  }

  .about-banner {
    padding: 70px 0px 0px 0px;
  }

  .details-left>div>p {
    padding-top: 0px;
    padding-right: 10px;
    font: normal normal normal 15px/20px Segoe UI;
  }

  .who-we-are>h1 {
    font: normal normal bold 25px/33px Segoe UI;
    text-align: center;
  }

  .about-banner-right>p {
    font: normal normal normal 11px/17px Segoe UI;
  }

  .aboutus-title {
    font: normal normal bold 25px/33px Segoe UI;
  }

  .details-right {
    max-width: 350px;
    padding-bottom: 20px;
  }

  .details-img-privacy {
    max-width: 240px;
    padding-right: 10px;
    padding-bottom: 20px;
  }

  .details-img-Commitment {
    max-width: 240px;
    padding-bottom: 20px;
  }

  .details-left>div>h2 {
    font: normal normal bold 20px/27px Segoe UI;
  }

  .features-value {
    padding: 20px 0px 0px 0px;
    gap: 0px;
    flex-direction: column;
  }

  .features-value-left,
  .features-value-right {
    width: 100%;
  }

  .what-we-do>h1 {
    padding: 30px 20px 0px 20px;
    text-align: center;
    font: normal normal bold 25px/33px Segoe UI;
  }

  .features-value-left>div>h1,
  .features-value-right>div>h1 {
    font: normal normal bold 20px/27px Segoe UI;
  }

  .features-value-right>div>p,
  .features-value-left>div>p {
    font: normal normal normal 15px/20px Segoe UI;
    padding-bottom: 10px;
  }

  .values {

    padding: 20px 60px 140px 60px;

  }
}

@media screen and (max-width: 675px) {
  .who-we-are {
    padding: 10px 0px 30px 0px;
  }

  .about-details-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-details-2 {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .details-right {
    max-width: 350px;
    padding-bottom: 20px;
  }

  .details-img-privacy {
    max-width: 240px;
    padding-right: 0px;
    padding-bottom: 20px;
  }

  .details-img-Commitment {
    max-width: 240px;
    padding-bottom: 20px;
  }

  .values {

    padding: 20px 10px 20px 10px;

  }
}

@media screen and (max-width: 540px) {
  .about-banner-left>.img {
    display: none;
  }

  .about-banner {
    flex-direction: column;
    padding: 80px 0px 0px 0px;

  }

  .about-banner-txt {
    flex-direction: column;
  }

  .aboutus-title {

    position: relative;
    top: unset;
  }

  .what-we-do>h1 {
    padding: 0px 20px 0px 20px;

  }

  .about-banner-right>p {
    padding: 10px 0px 10px 0px;
  }
}

@media screen and (max-width: 428px) {

  .who-we-are {
    padding: 10px 10px 10px 10px;
  }


}