.privacy-banner {
    background: url("../images/BG.svg");
    padding: 70px 0px 0px 0px;
    background-repeat: no-repeat;
    margin: auto;
    display: flex;
    background-size: cover;
}

.privacy-banner-txt {
    display: flex;
}

.privacy-banner-left {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.privacy-banner-left>h1 {
    font: normal normal bold 50px/80px Segoe UI;
    color: #ffffff;
}

.privacy-banner-left>p {
    font: normal normal normal 14px/22px Segoe UI;
    color: #FFFFFF;
    padding-right: 80px;
}

.privacy-banner-right {
    padding-top: 30px;
    width: 300px;
    height: 262px;

}

.privacy-main {
    padding: 30px 0px 170px 0px;
}

.privacy-main-heading {
    font: normal normal bold 38px/56px Segoe UI;
    padding-bottom: 0.5rem;
    color: #0A387D;
}

.privacy-main-policy {
    font: normal normal normal 15px/25px Segoe UI;
    color: #000000;
    padding-bottom: 1rem;
}

@media screen and (max-width: 1024px) {
    .privacy-banner {
        padding: 70px 00px 0px 00px;
    }

    .privacy-banner-txt {
        max-width: 90%;
    }

    .privacy-banner-left>p {
        padding-right: 40px;
    }

    .privacy-main {
        max-width: 90%;
        padding: 30px 0px 150px 0px;
    }
}

@media screen and (max-width: 768px) {

    .privacy-banner {

        padding: 70px 0px 0px 0px;

    }

    .privacy-banner-left>h1 {
        font: normal normal bold 25px/33px Segoe UI;
    }

    .privacy-banner-left>p {
        font: normal normal normal 15px/20px Segoe UI;
        padding-right: 0px;
    }

    .privacy-banner-right {
        width: 278px;
        height: 204px;

    }

    .privacy-main {
        padding: 20px 0px 140px 0px;
    }

    .privacy-main-heading {
        font: normal normal bold 25px/33px Segoe UI;
        padding-bottom: 0.5rem;
        color: #0A387D;
    }

    .privacy-main-policy {
        font: normal normal normal 15px/20px Segoe UI;
        color: #000000;
        padding-bottom: 1rem;
    }
}

@media screen and (max-width: 675px) {
    .privacy-main {
        padding: 20px 0px 20px 0px;
    }


}

@media screen and (max-width: 576px) {

    .privacy-banner {
        padding: 70px 0px 0px 0px;
        flex-direction: column;
    }

    .privacy-banner-txt {
        flex-direction: column;
    }

    .privacy-banner-left {
        padding: 20px 20px 20px 20px;
    }

    .privacy-banner-left {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .privacy-banner-right {
        padding-top: 0px;

    }

}