.background-img {
  background: url("../images/BG.svg");
  padding: 75px 0px 0px 0px;
  background-repeat: no-repeat;
  margin: auto;
  display: flex;
  background-size: cover;
  height: 100vh;
}

.background-img-txt {
  display: flex;
  flex-direction: row;
}

.background-left {
  /* display: flex; */
  padding-top: 66px;
  width: 50%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  gap: 7px;
}

.background-txt {
  color: #ffffff;
  font: normal normal 300 40px/50px Segoe UI;
  letter-spacing: 0;
  opacity: 1;
  margin-bottom: 5rem;
}

.background-para {
  font: normal normal normal 22px Segoe UI;
  letter-spacing: 0px;
  color: #ffffff;
}

.start-btn {
  background: transparent linear-gradient(180deg, #0866bd 0%, #0a387d 100%) 0% 0% no-repeat padding-box;
  font: normal normal bold 13px/20px Segoe UI !important;
  letter-spacing: 0px;
  color: #ffffff !important;
  text-transform: none !important;
  padding: 5px 20px !important;
}

.background-img-right {
  width: calc(56% - 65px);
  position: absolute;
  right: 0;
  background-size: cover;
  padding-left: 20px;
  padding-right: 40px;


}

.background-right {
  width: 50%;
  /* margin-bottom: -66px; */
}

.bt-right {
  width: 100%;
  position: relative;
  height: max-content;
}

.first-part {
  padding: 75px 0px 40px 0px;
  color: #0a387d;
}

.first-part .first-part-vr {
  border-left: 10px solid #0866bd;
}

.first-part-vr>h1 {
  font: normal normal bold 38px/56px Segoe UI;
  letter-spacing: 0px;
  color: #0a387d;
  text-transform: uppercase;
  border-left: 5px solid #0a387d;
  padding-left: 20px;
  margin-left: 2px;
  margin-top: 30px;
}

.second-part {
  padding: 10px 0px 50px 0px;
  color: #0a387d;
}

.second-part .second-part-vr {
  border-left: 10px solid #0866bd;
}

.second-part-vr>h1 {
  font: normal normal bold 38px/56px Segoe UI;
  letter-spacing: 0px;
  color: #0a387d;
  text-transform: uppercase;
  border-left: 5px solid #0a387d;
  padding-left: 20px;
  margin-left: 2px;
  margin-top: 30px;
}

.card-1 {

  text-align: center;
  color: #0a387d;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.inside-card {
  box-shadow: 0px 3px 6px #00000029;
  max-width: 345px;
  padding: 10px 24px 0px 24px;
  margin-bottom: 30px;
}

.inside-card>h3 {
  font: normal normal bold 19px/29px Segoe UI;
  margin-bottom: 10px;
}

.inside-card>p {
  font: normal normal normal 15px/25px Segoe UI;
}

.inside-card>img {
  padding-top: 10px;
  max-width: 150px;
}

/* ---------third-part------ */

.third-part {
  display: flex;
  flex-direction: column;
  padding: 0px 10px 0px 10px;
  color: #0a387d;
}

.third-part-inside {
  display: flex;
  padding-bottom: 40px;
  align-items: center;
}

.bt-diff {
  align-items: flex-start;
  display: flex;
  padding-right: 120px;
  flex-direction: column;
}

.bt-diff>h3 {
  font: normal normal bold 20px/30px Segoe UI;
  padding-bottom: 20px;
}

.bt-diff>p {
  font: normal normal normal 16px/25px Segoe UI;
  padding-bottom: 15px;
}

.bt-diff>h4 {
  font: normal normal bold 20px/30px Segoe UI;
}

.bt-diff-img>img {
  max-height: 270px;
  padding-right: 90px;
}


/* ------------------------------ */
.third-part-inside-1 {
  display: flex;
  padding-bottom: 40px;
  align-items: center;
}

.bt-diff-img-1>img {
  max-height: 270px;
  padding-left: 100px;
}

.bt-diff-1 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding-left: 90px;
  padding-right: 40px;
}

.bt-diff-1>h3 {
  font: normal normal bold 20px/30px Segoe UI;
  padding-bottom: 20px;
}

.bt-diff-1>p {
  font: normal normal normal 16px/25px Segoe UI;
  padding-bottom: 15px;
}

.bt-diff-1>h4 {
  font: normal normal bold 20px/30px Segoe UI;
}

.Home-sol-inside {
  padding: 30px 50px 10px 50px;
}

.Home-sol {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 9px #00000029;
  border: 1px solid #0866BD;
  border-radius: 5px;
  opacity: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.Home-sol-top>img {
  height: 38px;
}

.Home-sol-top>h1 {
  color: #0866bd;
  height: 48px;
  font: normal normal 700 50px/70px Segoe UI;
  text-transform: capitalize;
  display: block;
  margin-top: -19px;
}

.Home-sol-title {
  font: normal normal bold 23px/35px Segoe UI;
  color: #0A387D;
  display: flex;
  margin-top: -25px;
  text-align: center;
  text-transform: uppercase;
  flex-wrap: nowrap;
  padding: 0px 187px;
  justify-content: space-around;
}

.Home-sol-desc {
  font: normal normal normal 16px/25px Segoe UI;
  color: #0A387D;
  margin-top: 10px;
  padding-right: 70px;
}

.arrow-left,
.arrow-right {
  font-size: x-large !important;
  margin-right: 10px !important;
  color: #0A387D;
  cursor: pointer;
}

.arrow-left-disable,
.arrow-right-disable {
  cursor: not-allowed;
  font-size: x-large !important;
  margin-right: 10px !important;
  color: #0A387D;
  opacity: 0.4;
}

.homesol-arrow {
  display: block;
  float: right;
  margin-top: -70px;
  padding-right: 30px;
}

.slider-homesol {
  padding-top: 40px;
}

.slider-btn {
  background: transparent linear-gradient(180deg, #0866BD 0%, #0A387D 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  color: #FFFFFF;
  padding: 5px 15px 5px 15px;
  text-align: center;
  font: normal normal bold 17px/25px Segoe UI;
  margin-top: 40px;
  cursor: pointer;
}

.slider-casestudy {
  display: flex;
  flex-direction: column;
}

.slider-casestudy {
  width: 92% !important;
}

.slider-casestudy>img {
  display: block;
  width: 97%;
  border-radius: 5px;
}

.slider-casestudy>h2 {
  text-align: center;
  padding: 20px 20px 0px 10px;
  font: normal normal 600 20px/30px Segoe UI;
  color: #0A387D;
}

.casestudy-inside {
  padding: 25px 33px 140px 33px;
}

/* ------------------media-query------------ */

@media screen and (max-width: 1024px) {
  .Home-sol-top>h1 {
    color: #0866bd;
    height: 48px;
    font: normal normal 700 40px/60px Segoe UI;
  }

  .Home-sol-title {
    font: normal normal bold 22px/31px Segoe UI;
    padding: 0px 160px;
    justify-content: space-around;
  }

  .background-img-txt {
    max-width: 90%;
  }

  .first-part {
    max-width: 90%;
  }

  .card-1,
  .second-part,
  .third-part {
    max-width: 90%;
  }

  .casestudy-inside {
    padding: 25px 33px 120px 33px;
  }
}

@media screen and (max-width: 994px) {
  .background-txt {
    font: normal normal 300 30px/50px Segoe UI;

  }

  .background-left {
    gap: 0px;
    justify-content: space-evenly;
  }

  .background-para {
    font: normal normal normal 12px/17px Segoe UI;
    letter-spacing: 0px;
    color: #ffffff;
  }

  .third-part-inside {

    padding-bottom: 20px;
  }

  .bt-diff {

    padding-right: 100px;
  }

  .third-part-inside-1 {

    padding-bottom: 20px;
  }

  .bt-diff-1 {

    padding-left: 100px;
  }

  .bt-diff-img>img {
    max-height: 250px;
    padding-right: 90px;
  }

  .bt-diff-img-1>img {
    max-height: 250px;
    padding-left: 90px;
  }

  .Home-sol-title {
    font: normal normal bold 22px/31px Segoe UI;
    padding: 0px 50px 0px 160px;
  }

  .Home-sol-desc {
    padding-right: 10px;
  }

}

@media screen and (max-width: 880px) {
  .third-part {
    padding: 20px 10px 0px 10px;
  }

  .background-txt {
    font: normal normal 300 28px/33px Segoe UI;
    margin-bottom: 3rem;

  }

  .second-part-vr>h1 {
    font: normal normal bold 25px/33px Segoe UI;
  }

  .second-part {
    padding: 10px 0px 30px 0px;
    color: #0a387d;
  }
  .background-img{
    height: auto;
  }
  .background-right {
    /* margin-bottom: -49px;
    align-items: flex-end;
    padding-top: 30px; */
  }

  .background-para {
    font: normal normal normal 14px Segoe UI;
    letter-spacing: 0px;
    color: #ffffff;
  }

  .bt-diff-img>img {
    max-height: 200px;
    padding-right: 50px;
  }

  .bt-diff-img-1>img {
    max-height: 200px;
    padding-left: 50px;
  }

  .bt-diff {

    padding-right: 50px;
  }

  .bt-diff-1 {
    padding-left: 50px;
  }

  .Home-sol-title {
    font: normal normal bold 20px/27px Segoe UI;
    padding: 15px 0px 0px 0px;
    margin-top: 0px;
    justify-content: start;
    text-align: start;
  }

  .Home-sol-top>img {
    height: 29px;
  }

  .Home-sol-desc {
    font: normal normal normal 15px/20px Segoe UI;

  }

  .Home-sol-top>h1 {
    color: #0866bd;
    height: 45px;
    font: normal normal 700 40px/60px Segoe UI;
  }

  .casestudy-inside {
    padding: 25px 33px 140px 33px;
  }

}

@media screen and (max-width: 675px) {
  .third-part-inside {
    display: flex;
    flex-direction: column;
  }


  .third-part-inside-1 {
    display: flex;
    flex-direction: column-reverse;
  }

  .bt-diff-1 {
    padding-left: 0px;
  }

  .bt-diff-1>h4 {
    font: normal normal bold 13px/21px Segoe UI;
  }

  .bt-diff>h4 {
    font: normal normal bold 13px/21px Segoe UI;
  }

  .bt-diff-img {
    display: flex;
    justify-content: center;
  }

  .bt-diff-img-1 {
    display: flex;
    justify-content: center;
  }

  .bt-diff-img-1>img {
    padding-left: 0px;
  }

  .background-img-txt {
    display: flex;
    flex-direction: column;
  }

  .background-left {
    width: 100%;
  }

  .background-right {
    width: 100%;
  }

  .casestudy-inside {
    padding: 25px 33px 20px 70px;
  }

}

@media screen and (max-width: 428px) {
  .casestudy-inside {
    padding: 25px 33px 80px 70px;
  }
}