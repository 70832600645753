.team-details{
    padding: 30px 0px 170px 0px;
    color: #0866BD;
}
.team-details > h1{
    font: normal normal bold 38px/56px Segoe UI;
    padding-bottom: 10px;
    padding-top: 20px;
}

.team-card{
    display: grid;
    grid-template-columns: 27% 27% 27%;
    padding-bottom: 25px;
    gap: 40px;
    justify-content: space-evenly;
}

.single-team-card{
    box-shadow: 0px 3px 6px #00000029;
border-radius: 15px;

}
.single-team-card > img{
    margin: 20px;
    width: calc(100% - 40px); 
    border-radius: 10px;
    aspect-ratio: 2/3;
    background-color: #eee;
}
.single-team-card > h1{
    font: normal normal bold 23px/35px Segoe UI;
    padding: 0px 20px 0px 20px;
}
.single-team-card > h2{
    font: normal normal 600 17px/25px Segoe UI;
padding: 0px 20px 0px 20px;
}

.single-team-card > p{
    font: normal normal normal 13px/17px Segoe UI;
    padding: 0px 20px 20px 20px;
color: #0A387D;
}

.patner-card{
    background: #E5EFF8 0% 0% no-repeat padding-box;
border-radius: 9px;
display: flex;
flex-wrap: wrap;
flex-direction: row;
justify-content: space-around;
padding: 20px;
opacity: 1;
gap: 30px;
margin-bottom: 30px;
}
.patner-card > h1{
    font: normal normal bold 17px/25px Segoe UI;
color: #0A387D;
}

.sol-location{
    display: flex;
    flex-direction: row;
    gap: 80px;
}
.sol-location-left{
    width: 45%;
    display: flex;
    flex-direction: column;
    
}
.sol-location-right{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.sol-location-banner-left{
    background: #E5EFF8 0% 0% no-repeat padding-box;
    border-radius: 9px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 20px;
    padding: 15px 30px 15px 30px;
}

.sol-location-banner-right{
    background: #E5EFF8 0% 0% no-repeat padding-box;
    border-radius: 9px;
    display: flex;
    flex-direction: row;
    gap: 50px;
    padding: 15px 30px 15px 30px;
}

.sas-patner > h1 , .microsoft-patner > h1 {
    font: normal normal bold 17px/25px Segoe UI;
color: #0A387D;
}


.sol-location-right > h1 , .sol-location-left > h1{
    font: normal normal bold 38px/56px Segoe UI;
    padding-bottom: 10px; 
}

.head-ofice>h1,.india-office>h1{
    font: normal normal bold 20px/30px Segoe UI;
color: #0866BD;
}
.head-ofice > span , .india-office > span{
    font: normal normal normal 18px/32px Segoe UI;
    color: #0866BD;
}


@media screen and (max-width: 1024px)
{
    .single-team-card > h1 {
        font: normal normal bold 20px/30px Segoe UI;
    }
    .team-details {
        max-width: 90%;
       
            padding: 30px 0px 150px 0px;
         
    }
    .team-card {
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: space-between;
    }
   
    .single-team-card {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 960px){
    .sol-location {
        gap: 30px;
    }
    .sol-location-banner-right {
        
        gap: 10px;
        padding: 10px 0px 10px 20px;
    }
    .sol-location-left , .sol-location-right{
        width: 50%;
    }
    .sol-location-banner-left {
        gap: 10px;
        padding: 10px 0px 10px 20px;
    }
   
}

@media screen and (max-width: 768px){
    .team-details > h1 {
        font: normal normal bold 25px/33px Segoe UI;
        padding-bottom: 10px;
        text-align: center;
    }
    .sol-location {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
    .sol-location-left, .sol-location-right {
        width: 100%;
    }
    .sol-location-banner-left {
        gap: 50px;
        justify-content: flex-start;
        padding: 10px 10px 10px 20px;
    }
    .sol-location-banner-right {
        gap: 50px;
        padding: 10px 0px 10px 20px;
    }
    .sol-location-right > h1, .sol-location-left > h1 {
        font: normal normal bold 25px/33px Segoe UI;
        padding-bottom: 10px;
        text-align: center;
    }
    .team-details{
        padding: 20px 0px 140px 0px;
    }
    .team-card{
        grid-template-columns: 40% 40%;
        justify-content: space-evenly;
    }
    
}

@media screen and (max-width: 675px){
    .team-card {
        gap: 30px;
    }
.patner-card > h1 {
    font: normal normal bold 20px/27px Segoe UI;
    color: #0A387D;
}
.sas-patner > h1, .microsoft-patner > h1 {
    font: normal normal bold 20px/27px Segoe UI;
    color: #0A387D;
}
.team-details{
    padding: 20px 0px 20px 0px;
}
.single-team-card > p {
    font: normal normal normal 11px/17px Segoe UI;
    padding: 0px 20px 20px 20px;
    color: #0A387D;
}
.single-team-card > h2 {
    font: normal normal 600 14px/20px Segoe UI;
    padding: 0px 20px 0px 20px;
}
.single-team-card > h1 {
    font: normal normal bold 17px/25px Segoe UI;
}.single-team-card > img {
    margin: 10px;
    width: calc(100% - 20px);
    border-radius: 10px;
}
  
}
@media screen and (max-width: 540px){
    .team-card {
        display: grid;
        grid-template-columns: 83% ;
        justify-content: center;
        justify-items: center;
    }
    .sol-location-banner-left {
        gap: 20px;
        flex-direction: column;
        justify-content: flex-start;
        padding: 10px 10px 10px 20px;
        align-items: center;
    }
    .sol-location-banner-right {
        gap: 20px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0px 10px 0px;
    }
    .head-ofice>h1, .india-office>h1 {
        text-align: center;
    }
    .head-ofice , .india-office{
        width: 100%;
        text-align: center;
    }
    .single-team-card > img {
        margin: 10px;
        height: auto;
        width: calc(100% - 20px);
        border-radius: 10px;
    }
    .single-team-card {
        width: calc(100% - 30%);
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 15px;
        /* width: 100%; */
    }
    
    .team-details {
        padding: 10px 0px 10px 0px;
    }
   
}

@media screen and (max-width: 428px){
    .patner-card{
       
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    opacity: 1;
    gap: 30px;
    }
    .single-team-card {
        width: calc(100% - 20%);
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 15px;
    }
}
