.Footer-main {
  padding: 15px 0;
  background: transparent linear-gradient(180deg, #094691 0%, #052349 100%) 0% 0% no-repeat padding-box;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
}

.Footer {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 10px;
  align-items: start;
  justify-content: space-between;
}

.Footer>div {
  text-align: center;
  padding: 4px 0;
}

.Footer-mid {
  height: 40px;
}

.Footer-mid {
  display: flex;
  gap: 10px;
  text-align: center;
  align-items: center;
  list-style-type: none;
  font: normal normal 600 13px/20px Segoe UI;
  justify-content: space-between;
  gap: 45px;
}

.Footer-mid>li>a {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 1px;
}

.Footer-end>h1 {
  font: normal normal 300 20px/30px Segoe UI;
  letter-spacing: 0px;
  color: #ffffff;
}

.connect {
  letter-spacing: 2px !important;
  background-color: #0866bd !important;
  text-transform: capitalize;
  font: normal normal bold 12px/24px Segoe UI !important;
}

.Footer-end {
  display: grid;
  justify-items: start;
}

.copyright-span {
  display: block;
  text-align: center;
  margin-top: -15px;
  font: normal normal 300 10px/15px Segoe UI;
  color: #ffffff;
  opacity: 0.7;
}

.Footer-logo {
  cursor: pointer;
  padding: 1px;
  margin-top: 5px;
}

@media screen and (max-width: 1024px) {
  .Footer {
    max-width: 90%;
  }

  .Footer-logo {
    margin-top: 5px;
  }
}

@media screen and (max-width: 960px) {
  .Footer-mid {

    font: normal normal 600 12px/18px Segoe UI;
    gap: 15px;
  }

  .Footer-end>h1 {
    font: normal normal 300 17px/25px Segoe UI;

  }
}

@media screen and (max-width: 853px) {

  .Footer-mid {
    margin-left: -43px;
  }
}

@media screen and (max-width: 768px) {
  .Footer {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap: 10px;
    align-items: start;
    max-width: 98%;
  }

  .Footer-mid {
    margin-left: -43px;
    gap: 7px;
  }
}

@media screen and (max-width: 675px) {
  .Footer-main {
    position: relative;

  }

  .Footer {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    align-content: center;
    max-width: 90%;

  }

  .Footer-mid {
    gap: 25px;
    height: max-content;
    padding-left: 0px;
    margin-left: 0px;
    margin: 1rem;
  }

  .Footer-end {
    justify-items: center;
  }

  .copyright-span {
    padding-top: 15px;
    margin-top: 0px;

  }

}

@media screen and (max-width: 428px) {
  .Footer-mid {
    flex-direction: column;
    gap: 15px;

  }

  .Footer-end>h1 {
    margin-bottom: 0px;
  }

}