@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Segoe UI", sans-serif;
}

html {
  font-size: 62.5%;
}

.menu-style {
  align-items: center;
}

a {
  text-decoration: none;
}

.navbar-item {
  box-shadow: 0px 3px 6px #00000029;
  padding: 0 65px 0 37px;
}

.css-i4bv87-MuiSvgIcon-root {
  font-size: 2.7rem !important;
}

.logo {
  max-width: 200px;
  max-height: 50px;
  cursor: pointer;

}


.dropdown-menu {
  margin-top: 7px !important;
}

.connect-btn {
  letter-spacing: 1.5px !important;
  background: #0866bd 0% 0% no-repeat padding-box !important;
  font: normal normal bold 13px/25px Segoe UI !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border-radius: 5px;
  padding: 5px 10px !important;
  margin-left: 25px !important;
}

.nav-header {
  font: normal normal 600 13px/20px Segoe UI !important;
  letter-spacing: 0.5px;
  color: #0A387D !important;
  text-transform: uppercase;
  opacity: 1;
  --bs-nav-link-color: none;
  margin: 0px 15px !important;
}

.nav-header-dropdown {
  color: #0A387D !important;
  font: normal normal 600 12px/18px Segoe UI !important;
}

@media screen and (max-width: 1024px) {
  .navbar-item {
    padding: 0 30px 0 10px;
  }

  .connect-btn {
    margin-left: 20px !important;
  }

  .wrapp {
    max-width: 90%;
  }

}

@media screen and (max-width: 960px) {
  .nav-header {
    margin: 0px 5px !important;
  }

  .connect-btn {
    margin-left: 5px !important;
    padding: 5px 5px !important;
  }


}

@media screen and (max-width: 768px) {
  .navbar-item {
    padding: 0 0px 0;
  }
  .menu-style{align-items: unset;}

  .nav-header {
    margin: 0px 5px !important;
  }

  .connect-btn {
    padding: 5px 10px !important;
  }

  .logo {

    margin-left: -10px;
  }

}