.carrer-img-banner {
  background: url("../images/BG.svg");
  background-repeat: no-repeat;
  display: flex;
  padding: 70px 0px 30px 0px;
  background-size: cover;
}

.carrer-banner-txt {
  display: flex;
  flex-direction: row;
  /* align-items: flex-end; */
}

.carrer-banner-txt-left {
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: center;
}

.carrer-banner-txt-left>h2 {
  font: normal normal bold 38px/57px Segoe UI;
  color: #ffffff;
}

.carrer-banner-txt-left>div>h1 {
  font: normal normal bold 70px/100px Segoe UI;
  color: #ffffff;
  opacity: 0.5;
}

.carrer-banner-txt-left>div>p {
  font: normal normal normal 14px/24px Segoe UI;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  /* padding: 10px 40px 20px 0px; */
}

.carrer-banner-txt-right {
  position: relative;
  width: 508px;
  height: 265px;
  bottom: -31px;
}

.job-opens {
  padding: 30px 65px 170px 65px;
}

.job-position {
  border-bottom-width: 1px;
}

.job-position>th {
  font: normal normal bold 17px/25px Segoe UI !important;
  color: #0866bd;
  padding-top: 10px;
  padding-bottom: 10px;
}

.job-position>td {
  font: normal normal 400 15px/25px Segoe UI;
  color: #0866bd;
  padding-top: 10px;
  padding-bottom: 10px;
}

.job-more-details {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #0866bd !important;
  border-radius: 5px;
  font: normal normal bold 12px/18px Segoe UI !important;
  color: #0866bd !important;
  text-transform: capitalize !important;
  margin-top: 10px !important;
}

.load-more {
  margin-top: 30px !important;

  margin-bottom: 20px !important;
  background: #0866bd 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  font: normal normal bold 12px/18px Segoe UI !important;

  color: #ffffff !important;
  opacity: 1;
}

.load-more-inactive {
  display: none !important;
  color: #ffffff !important;
  opacity: 1;
  cursor: not-allowed !important;
}

.more-detail {
  font: normal normal bold 12px/18px Segoe UI !important;
  color: #0866bd !important;
  text-transform: capitalize !important;
  border: solid #0866bd 1px !important;
}

.more-detail-col {
  text-align: end;
}

.display {
  display: none;
}

.single-career {
  padding: 30px 0px 170px 0px;
}

.single-career>div>h1 {
  font: normal normal bold 38px/56px Segoe UI;
  color: #0a387d;
}

.single-career-top-main {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
}

.single-career-top>h2 {
  font: normal normal bold 17px/25px Segoe UI;
  color: #0866bd;
  padding-top: 10px;
}

.single-career-top>span {
  font: normal normal normal 17px/25px Segoe UI;
  color: #0866bd;
}

.single-career-middle-heading {
  font: normal normal bold 17px/25px Segoe UI;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #0866bd;
}

.single-career-middle {
  font: normal normal normal 17px/25px Segoe UI;
  color: #000000;
}

.single-career-end {
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
}

.apply-btn,
.apply-btn:hover {
  border: none;
  color: #ffffff;
  background: #0866bd 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  font: normal normal bold 13px/20px Segoe UI;
  margin-top: 20px;
  margin-bottom: 20px;
  float: right;
}

.back-btn {
  border: none;
  color: #ffffff;
  background: #0866bd 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  font: normal normal bold 13px/20px Segoe UI;
  margin-top: 20px;
  margin-bottom: 20px;
}

.loading-progress {
  margin: 18% auto 0 auto !important;

  display: block !important;
}

@media screen and (max-width: 1024px) {
  .job-opens {
    padding: 20px 40px 150px 40px;
  }

  .single-career {
    max-width: 90%;
    padding: 30px 0px 150px 0px;
  }

  .carrer-banner-txt {
    max-width: 90%;
  }

  .carrer-banner-txt-left>div>h1 {
    font: normal normal bold 60px/90px Segoe UI;
  }

  .carrer-banner-txt-left>h2 {
    font: normal normal bold 35px/50px Segoe UI;
  }

  .carrer-img-banner {
    padding: 70px 0px 0px 0px;
  }

  .carrer-banner-txt-left {
    padding-bottom: 30px;
  }

  .carrer-banner-txt-right {
    position: relative;
    width: 430px;
    height: 256px;
    bottom: -22px;
  }
}

@media screen and (max-width: 960px) {
  .carrer-banner-txt-left>div>h1 {
    font: normal normal bold 50px/70px Segoe UI;
  }

  .carrer-banner-txt-left>h2 {
    font: normal normal bold 30px/50px Segoe UI;
  }

  .carrer-banner-txt-left>div>p {
    font: normal normal normal 12px/18px Segoe UI;
  }

  /* .job-position {
    font: normal normal 600 17px/25px Segoe UI;
  } */
  .job-place {
    font: normal normal bold 17px/25px Segoe UI;
  }

  .carrer-banner-txt-left {
    padding-bottom: 20px;
  }

  .single-career {
    padding: 25px 0px 140px 0px;
  }

  .carrer-banner-txt-right {
    position: relative;
    width: 362px;
    height: 216px;
    bottom: -15px;
  }
}

@media screen and (max-width: 768px) {
  .job-opens {
    grid-template-columns: 250px 250px;
    column-gap: 23px;
    row-gap: 20px;
    justify-content: center;
  }

  .job-opens {
    padding: 20px 20px 140px 20px;
  }

  .carrer-img-banner {
    padding: 70px 0px 0px 0px;
  }

  .carrer-banner-txt-left>h2 {
    font: normal normal bold 25px/33px Segoe UI;
  }

  .carrer-banner-txt-left>div>h1 {
    font: normal normal bold 40px/60px Segoe UI;
  }

  .carrer-banner-txt-left {
    padding-bottom: 10px;
  }

  .carrer-banner-txt-left>div>p {
    font: normal normal normal 11px/17px Segoe UI;
  }

  .Footer-logo {
    width: 150px;
  }

  .single-career-top-main {
    grid-template-columns: auto auto auto;
  }

  .single-career-top>h2 {
    font: normal normal normal 15px/20px Segoe UI;
  }

  .single-career>div>h1 {
    font: normal normal bold 25px/33px Segoe UI;
  }

  .single-career-top>span {
    font: normal normal bold 15px/20px Segoe UI;
  }

  .single-career-end {
    display: grid;
    grid-template-columns: auto;
    gap: 0px;
  }

  .single-career {
    padding: 25px 0px 90px 0px;
  }

  .carrer-banner-txt-right {
    position: relative;
    width: 325px;
    height: 221px;
    bottom: -27px;
  }

  .carrer-banner-txt-left {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .carrer-img-banner {
    padding: 70px 0px 0px 0px;
  }
}

@media screen and (max-width: 675px) {
  .carrer-img-banner {
    padding: 70px 0px 0px 0px;
  }

  .carrer-banner-txt-left>div>p {
    padding-right: 20px;
  }

  .carrer-banner-txt {
    flex-direction: column;
  }

  .carrer-banner-txt-left {
    width: 100%;
    padding-bottom: 0px;
  }

  .carrer-banner-txt-right {
    width: 100%;
    height: 200px;
    bottom: 0px;
  }

  .job-opens {
    grid-template-columns: auto;
    column-gap: 23px;
    padding: 20px 20px 20px 20px;
  }

  /* .load-more, .load-more-inactive {
    margin-bottom: 280px !important;
    
  } */
  .single-career {
    padding: 25px 0px 20px 0px;
  }
}

@media screen and (max-width: 540px) {
  .more-detail-col {
    text-align: start;
    border-bottom-width: 1px !important;
    padding-bottom: 10px !important;
  }

  
  .job-position>th {
    display: none;
  }

  .job-position {
    display: flex;
    border-bottom-width: 0px;
    flex-direction: column;
  }

  .more-detail {
    font: normal normal bold 9px/14px Segoe UI !important;
  }

  .display {
    display: inline;
  }

  .job-position>td {
    font: normal normal normal 10px/15px Segoe UI;
    padding-top: 0px;
    padding-bottom: 5px;
    border-bottom-width: 0px;
  }

  .position {
    font: normal normal 600 13px/20px Segoe UI !important;
  }

  .place {
    font: normal normal 600 12px/18px Segoe UI !important;
  }

  .single-career-top-main {
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 428px) {
  .single-career {
    padding: 25px 0px 80px 0px;
  }
}