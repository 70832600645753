.connectForm {
  padding-top: 30px;
  padding-left: 10px;
}
.connect-background-img-txt{ display: flex;}

.connect-background-img {
  background: url("../images/BG.svg");
  padding: 70px 0px 0px 0px;
  background-repeat: no-repeat;
  margin: auto;
  display: flex;
  background-size: cover;
}

.connect-background-img-left {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.connect-background-img-left>h1 {
  font: normal normal bold 38px/57px Segoe UI;
  color: #ffffff;
}

.connect-background-img-left>p {
  font: normal normal bold 70px/100px Segoe UI;
  color: #ffffff;
  opacity: 0.5;
}

.connect-backgrounc-img-right {
  width: 387px;
  height: 203px;
  margin-bottom: -5px;
}

.contact-page {
  padding: 20px 0px 90px 0px;
  color: #0866bd;
}

.contact-page>p {
  font: normal normal bold 23px/35px Segoe UI;
}

.lets-connect-main {
  padding: 10px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-around;
}

.connect-row {
  padding-top: 10px !important;
}

.connect-form {
  font: normal normal normal 17px/27px Segoe UI;
  color: #0866bd;
  width: 185px;

}

.lets-connect-form {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #c9c9c9;
}

.connect-checkbox {
  margin-bottom: 10px;
}

.connect-checkbox>.form-check-input {
  padding-right: 15px;
  padding-top: 12px;
  padding-bottom: 3px;
}

.connect-checkbox>.form-check-label {
  font: normal normal bold 13px/20px Segoe UI;
  margin-left: 10px;
}

.lets-connect-btn {
  background: #0a387d;
  border: none;
  font: normal normal bold 13px/23px Segoe UI;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.connect-img {
  background: url("../images/contact.png");
  display: block;
  margin-left: auto;

  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 30px;
}

.connect-img>.img-fluid {
  max-width: 100%;
  height: 700px;
}

.connect-img-location1 {
  padding-top: 314px;
  font: normal normal bold 20px/30px Segoe UI;
  color: #FFFFFF;
  width: 50%;
  text-align: start;
}

.connect-img-location1>p {
  font: normal normal normal 14px/30px Segoe UI;
  color: #FFFFFF;
  margin-bottom: 0rem;
}

.connect-img-location2 {
  padding-top: 30px;
  font: normal normal bold 20px/30px Segoe UI;
  color: #FFFFFF;
}

.connect-img-location2>p {
  font: normal normal normal 14px/30px Segoe UI;
  color: #FFFFFF;
  margin-bottom: 0rem;
}

@media screen and (max-width:1200px) {
  .connect-img-location1 {
    padding-top: 244px;

  }

}

@media screen and (max-width: 1024px) {
  .connect-img-location1 {
    padding-top: 227px;
  }
  .connect-background-img-txt {
    max-width: 90%;
  }
  .connect-background-img-left {
    max-width: 90%;
  }

  .connect-background-img-left>h1 {
    font: normal normal bold 35px/50px Segoe UI;
  }

  .connect-background-img-left>p {
    font: normal normal bold 60px/90px Segoe UI;
  }

  .connect-background-img {

    padding: 70px 0px 0px 0px;
  }

  .contact-page {
    padding: 20px 0px 90px 0px;
    max-width: 90%;

  }
}

@media screen and (max-width:991px) {
  .connect-img-location1 {
    padding-top: 575px;
  }

  .connect-img {
    display: unset;
    margin-left: unset;
    border-radius: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 30px;
  }
}


@media screen and (max-width: 960px) {

  .connect-background-img-left>h1 {
    font: normal normal bold 30px/50px Segoe UI;
  }

  .connect-background-img-left>p {
    font: normal normal bold 50px/70px Segoe UI;
  }

}

@media screen and (max-width: 768px) {
  .connect-background-img-left>h1 {
    font: normal normal bold 25px/33px Segoe UI;
  }

  .connect-background-img-left>p {
    font: normal normal bold 40px/60px Segoe UI;

  }

  .connect-background-img {

    padding: 70px 0px 0px 0px;
  }

  .contact-page {
    padding: 10px 0px 190px 0px;
    color: #0866bd;
  }

  .contact-page>p {
    font: normal normal bold 17px/27px Segoe UI;
  }

  .connect-backgrounc-img-right {
    width: 340px;
    height: 180px;
  }
}

@media screen and (max-width: 675px) {
  .contact-page {
    padding: 20px 0px 90px 0px;
  }

}

@media screen and (max-width: 576px) {

  .connect-background-img-left {
    width: 100%;
    padding: 20px 20px 0px 20px;

  }
  .connect-background-img-txt {
    flex-direction: column;
  }
  .connect-background-img {
    flex-direction: column;
  }

  .connect-img {

    border-radius: unset;
    background-repeat: no-repeat;
    background-size: contain;
    padding-left: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .connect-img-location1 {
    padding-top: 260px;
  }
}

@media screen and (max-width: 506px) {
  .connect-img-location1 {
    padding-top: 260px;
  }
}

@media screen and (max-width: 468px) {
  .connect-img-location1 {
    padding-top: 230px;
  }

  .connect-img-location1>p {
    font: normal normal normal 13px/21px Segoe UI;
  }

  .connect-img-location2>p {
    font: normal normal normal 13px/21px Segoe UI;
  }
}

@media screen and (max-width: 428px) {
  .connect-img-location1 {
    padding-top: 200px;
  }

  .contact-page {
    padding: 20px 20px 90px 20px;
  }

}

@media screen and (max-width: 376px) {
  .connect-img-location1 {
    padding-top: 150px;
  }

  .connect-img-location2 {
    padding-top: 20px;
    font: normal normal bold 20px/30px Segoe UI;
    color: #FFFFFF;
  }
}