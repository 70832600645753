/* ------------Main_Solution------------ */

.solution-main {
  padding: 95px 0px 0px 0px;
}
.solution-main > h1 {
  font: normal normal bold 50px/56px Segoe UI;
  color: #0a387d;
}

.solution-main > h2 {
  text-transform: uppercase;
  font: normal normal 300 38px/66px Segoe UI;
  color: #0a387d;
}

/* ------------Lift, Idea------------ */

 .solution-feature-acc {
  padding: 65px 0px 170px 0px;
  color: #0a387d;
}
.solution-feature  {
  padding: 20px 0px 170px 0px;
  color: #0a387d;
}


.solution-feature > img {
  height: 45px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.solution-feature >span , .solution-feature-acc >span{
    height: 38px;
    margin-top: 45px;
    font: normal normal bold 51px/100px Segoe UI;

    color: #0866BD;
    text-transform: uppercase;
}

.solution-feature > h1 , .solution-feature-acc>h1 {
  font: normal normal bold 20px/30px Segoe UI;
  margin-bottom: 5px;
}

.solution-feature > p ,.solution-feature-acc > p {
  font: normal normal normal 15px/25px Segoe UI;
  letter-spacing: 0.5px;
  margin-right: 70px;
}

.solution-features-Benfites > h1 {
  font: normal normal bold 20px/30px Segoe UI;
  margin-top: 10px;
}

.features-main {
  display: grid;
  grid-template-columns: auto auto auto;
}

.features-left > h2 {
  font: normal normal bold 18px/27px Segoe UI;
  padding-top: 10px;
}

.features-left > p {
  font: normal normal normal 15px/25px Segoe UI;
  padding-top: 10px;
}

.features-mid {
  display: block;
  margin: auto;
}

.features-mid > img {
  height: 230px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
}

.features-end > h2 {
  font: normal normal bold 18px/27px Segoe UI;
  padding-top: 10px;
}

.features-end > p {
  font: normal normal normal 15px/25px Segoe UI;
}

.player-wrapper {
  padding-top: 20px;
}

.react-player {
  display: block;
  margin-left: auto;
  margin-right: auto;
}


/* -----------Accelerated Solution------------ */

.acc-features-main{
  display: flex;
  grid-template-columns: auto auto auto;
}

.acc-features-left {
  padding-right: 60px;
}
.acc-features-left > h2{
  font: normal normal bold 18px/28px Segoe UI;
  padding-top: 10px;
}

.acc-features-left > p {
  font: normal normal normal 15px/25px Segoe UI;
}

.acc-features-end{
padding-left: 80px;
}

.acc-features-end > h2{
  font: normal normal bold 18px/28px Segoe UI;
  padding-top: 10px;
}

.acc-features-end > p {
  font: normal normal normal 15px/25px Segoe UI;
}

/* -----------Custom Solution------------ */
.custom-solution{
  padding: 60px 0px 170px 0px;
  color: #0a387d;
}

.custom-solution >span{
  height: 38px;
  margin-top: 45px;
  font: normal normal bold 51px/100px Segoe UI;

  color: #0866BD;
  text-transform: uppercase;
}

.custom-solution > h1 {
font: normal normal bold 20px/30px Segoe UI;
margin-bottom: 5px;
}

.custom-solution > p {
font: normal normal normal 15px/25px Segoe UI;
letter-spacing: 0.5px;
margin-right: 170px;
margin-bottom: 20px;
}

.custom-solution-feature{
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.custom-solution-feature-1{
  padding-left: 10px;
  border-left: 3px solid #0866bd;
  margin-bottom: 25px;
}

.Digital-service{
  display: grid;
  grid-template-columns: auto auto;
}

.Digital-service-1 > p{
  padding-top: 5px;
  padding-bottom: 20px;
  font: normal normal normal 15px/25px Segoe UI;
}

.Digital-service > img {
  height: 150px;
  padding-left: 60px;
}
.custom-solution-feature-1 > h2{
  font: normal normal 600 14px/25px Segoe UI;

}

@media screen and (max-width: 1024px){
  .solution-main {
    padding: 95px 0px 0px 0px;
    max-width: 90%;
}
.solution-feature-acc{
  max-width: 90%;
}
.solution-feature {
  padding: 15px 0px 190px 0px;
  max-width: 90%;
}
.custom-solution {
  max-width: 90%;
  padding: 60px 0px 150px 0px;
  color: #0a387d;
}
.solution-main > h1 {
  font: normal normal bold 35px/50px Segoe UI;
}
.solution-main > h2 {
  font: normal normal 300 35px/50px Segoe UI;
}
.solution-feature > img {
  height: 35px;
}
.acc-features-end {
  padding-left: 50px;
}
.acc-features-left {
  padding-right: 40px;
}

}

@media screen and (max-width: 960px){
  .acc-features-end {
    padding-left: 20px;
  }
  .acc-features-left {
    padding-right: 0px;
  }
  .custom-solution-feature {
    grid-template-columns: auto auto auto ;
}
  }


@media screen and (max-width: 768px){
  .solution-main {
    padding: 80px 0px 0px 0px;
}
  .solution-feature {
    padding: 15px 0px 140px 0px;
}
.custom-solution {
  padding: 60px 20px 140px 20px;
  color: #0a387d;
}
.solution-feature > p {
  font: normal normal normal 15px/26px Segoe UI;
  margin-right: 30px;
}
.solution-main > h1 {
  font: normal normal bold 25px/33px Segoe UI;
}
.solution-main > h2 {
  font: normal normal 300 20px/27px Segoe UI;
}

.solution-features-Benfites > h1 {
  font: normal normal bold 20px/27px Segoe UI;
  margin-top: 10px;
}
.features-left > p {
  font: normal normal normal 15px/20px Segoe UI;
  padding-top: 10px;
}
.features-left > h2 {
  font: normal normal bold 15px/20px Segoe UI;
  padding-top: 10px;
}
.features-end > h2 {
  font: normal normal bold 15px/20px Segoe UI;
  padding-top: 10px;
}
.features-end > p {
  font: normal normal normal 15px/20px Segoe UI;
}
.solution-feature > img {
  height: 35px;
  margin-top: 10px;
}
.features-main {
  grid-template-columns: auto ;
}
.solution-feature > h1 {
  font: normal normal bold 15px/26px Segoe UI;
  margin-bottom: 5px;
}
.react-player {
  width: 359px !important;
  height: 200px !important;
  margin: 0 auto;
  position: inherit;
}
.acc-features-main {
  flex-direction: column;
}
.solution-feature >span ,.solution-feature-acc > span{
  font: normal normal bold 25px/33px Segoe UI;
}
.custom-solution >span {
  font: normal normal bold 25px/33px Segoe UI;
}
.custom-solution-feature {
 
  grid-template-columns: auto auto;
}

}

@media screen and (max-width: 675px){

.acc-features-left {
    padding-right: 0px;
}



.acc-features-end {
    padding-left: 0px;
}
.custom-solution > p {
  font: normal normal normal 13px/23px Segoe UI;
  letter-spacing: 0.5px;
  margin-right: 40px;
  margin-bottom: 20px;
}
.solution-feature-acc {
  padding: 65px 0px 0px 0px;
  
}
.Digital-service {
  grid-template-columns: auto;
  justify-items: center;
}
.Digital-service > img {
  height: 150px;
  padding-left: 0px;
}


.solution-feature {
  padding: 15px 0px 30px 0px;
}
.custom-solution {
  padding: 50px 0px 30px 0px;
  margin-top: 20px;
  color: #0a387d;
}
}

@media screen and (max-width: 540px){
  .react-player {
    width: 100% !important;
    height: 100% !important;
    margin: 0 auto;
    position: inherit;
  }
  .custom-solution-feature{
    grid-template-columns: auto  ;
  }
}
@media screen and (max-width: 428px){
 
.custom-solution {
  padding: 40px 20px 80px 20px;
  
}
}

